import throttle from 'lodash.throttle';
import { ComponentProps, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { roleOptions } from '../ownership/RoleOptions';
import { InputStyle } from '../shared/form-control/Input';
import { SearchInput } from '../shared/form-control/SearchInput';
import FilterTag, { DueDateFilter, FilterSelectMode } from '../shared/tags/FilterTag';
import useDebounce from '../../hooks/useDebounce';
import { ApiResponse } from '../../models/ApiResponse';
import { ClientFormStatus, formStatusKeys } from '../../models/ClientFormStatus';
import { ClientFormUserRoleKeys, ClientFormUserRoleValues } from '../../models/ClientFormUserRoles';
import { DateRange } from '../../models/DateRange';
import { FormType } from '../../models/FormTypes';
import { Roles } from '../../models/Role';
import { currentUserAtom } from '../../recoil/atoms/Auth';
import { currentClientAtom } from '../../recoil/atoms/Clients';
import ClientService from '../../services/ClientService';
import ClientTemplateModuleService from '../../services/ClientTemplateModuleService';
import DateUtils from '../../utils/DateUtils';
import { Option } from '../Option';
import { useTranslation } from 'react-i18next';
import SkeletonLoader from '../shared/skeleton-loader/SkeletonLoader';
import { nextTick } from '../../utils/ReactUtils';
import ActivityList from './ActivityList';
import { FormListItem } from '../../models/Form';
import DynamicFilterTags from '../shared/tags/DynamicFilterTags';
import { useLocation, useParams } from 'react-router-dom';
import ClientFormService from '../../services/ClientFormService';
import { useActivites } from '../../contexts/ActivitiesContext';
import { RiskRating } from '../../utils/RiskUtils';
import usePermissions from '../../hooks/permissions/usePermissions';
import { EventSystem } from '../../events/EventSystem';
import { useCurrentRoute } from '../../hooks/useCurrentRoute';
import Button, { ButtonType } from '../shared/form-control/Button';
import { useStoredFilters } from '../../hooks/useStoredFilters';
import { useLocalStorageState } from '../../hooks/useLocalStorageState';
import withSlot, { SlotDefinitions } from '../../wrappers/withSlot';
import useSlot from '../../hooks/useSlots';
import { useItemSelection } from '../../contexts/select-items/SelectItemsContext';
import { PeriodicReviewStatus } from '../../models/PeriodicReview';
import LanguageUtils from '../../utils/LanguageUtils';
import { ModuleType } from '../../models/Module';
import { DistributionStatusFilter, DistributionStatusFilterKeys } from '../../models/Distribution';
import { useFeatureFlags } from '../../contexts/FeatureFlagContext';

type ActivitiesProps = {
  skeletonRows?: number;
};

export const ARCHIVED_VALUE = 99;
const FORM_STATUS_PREFIX = 'form-status-';
const PERIODIC_STATUS_PREFIX = 'periodic-status-';
const DISTRIBUTION_STATUS_PREFIX = 'distribution-status-';

const Activities = withSlot<ActivitiesProps, SlotDefinitions<['AboveList', 'AfterSearch', 'AboveFilters']>>((props) => {
  const { featureFlags } = useFeatureFlags();
  const { skeletonRows = 5 } = props;

  const AboveListSlot = useSlot(props, 'AboveList');
  const AfterSearchSlot = useSlot(props, 'AfterSearch');
  const AboveFiltersSlot = useSlot(props, 'AboveFilters');

  const { forCurrentUser, type, loading, moduleSectionId } = useActivites();
  const {
    t,
    i18n: { language: currentLanguage },
  } = useTranslation(['home-page', 'module', 'common', 'distribution']);
  const params = useParams<{ moduleId: string }>();
  const { search: searchLocationString } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(searchLocationString), [searchLocationString]);
  const currentClient = useRecoilValue(currentClientAtom);
  const { id: currentPageType, match: currentRouteInfo } = useCurrentRoute();
  const modulePageId = useMemo(() => currentRouteInfo?.params.moduleId || '', [currentRouteInfo?.params.moduleId]);
  const currentPageId = useMemo(
    () => `${currentClient?.id}-${currentPageType}${modulePageId ? '-' + modulePageId : ''}`,
    [currentClient?.id, currentPageType, modulePageId],
  );
  const currentUser = useRecoilValue(currentUserAtom);
  const pageContentRef = useRef<HTMLDivElement>(null);
  const [activities, setActivities] = useState<ApiResponse<FormListItem[]> | null>(null);
  const [clientUsers, setClientUsers] = useState<Option<string, boolean>[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isActivitiesLoading, setIsActivitiesLoading] = useState(true);
  const [activitySearchPhrase, setActivitySearchPhrase] = useLocalStorageState(`${currentPageId}-search`, '');
  const [sortBy, setSortBy] = useState('+dueDateUtc,+subtitle');
  const debouncedSearchTerm = useDebounce(activitySearchPhrase, 500);
  const [templateFilterSearch, setTemplateFilterSearch] = useState('');
  const [documentFilterSearch, setDocumentFilterSearch] = useState('');
  const [selectedDueDateRangeFilter, setSelectedDueDateRangeFilter] = useState<DateRange | null>(null);
  const [picklistFilters, setPicklistFilters] = useLocalStorageState<Option<string, string[]>[]>(`${currentPageId}-dynamic-filters`, []);

  const hasPicklistFilters = useMemo(() => picklistFilters.some((x) => x.value.length), [picklistFilters]);
  const [allFiltersDataLoaded, setAllFiltersDataLoaded] = useState(false);

  const hasPermission = usePermissions();

  const defaultStatusFilters = useMemo<ComponentProps<typeof FilterTag>['options']>(() => {
    const filters = [
      { id: 'activity_status_heading', text: t('common:form-status.heading'), value: false, disabled: true, hasDivider: true },
      { id: `${FORM_STATUS_PREFIX}${ClientFormStatus.NotStarted}`, text: t(formStatusKeys[ClientFormStatus.NotStarted]), value: false },
      { id: `${FORM_STATUS_PREFIX}${ClientFormStatus.InProgress}`, text: t(formStatusKeys[ClientFormStatus.InProgress]), value: false },
      {
        id: `${FORM_STATUS_PREFIX}${ClientFormStatus.SubmittedForValidation}`,
        text: t(formStatusKeys[ClientFormStatus.SubmittedForValidation]),
        value: false,
      },
      {
        id: `${FORM_STATUS_PREFIX}${ClientFormStatus.SubmittedForApproval}`,
        text: t(formStatusKeys[ClientFormStatus.SubmittedForApproval]),
        value: false,
      },
      { id: `${FORM_STATUS_PREFIX}${ClientFormStatus.Completed}`, text: t(formStatusKeys[ClientFormStatus.Completed]), value: false },
      {
        id: `${FORM_STATUS_PREFIX}${ARCHIVED_VALUE}`,
        text: t(`common:list.filter.archived${type === 'documents' ? '-documents' : ''}`),
        value: false,
      },
      { id: 'periodic_status_heading', text: t('common:periodic-review-status.heading'), value: false, disabled: true, hasDivider: true },
    ];
    if (currentPageType === 'client-home') {
      const distributionFilters = featureFlags.distributionList
        ? [
            { id: 'distribution_status_heading', text: t('distribution:filters.heading'), value: false, disabled: true, hasDivider: true },
            {
              id: `${DISTRIBUTION_STATUS_PREFIX}${DistributionStatusFilter.Active}`,
              text: t(DistributionStatusFilterKeys[DistributionStatusFilter.Active]),
              value: false,
            },
            {
              id: `${DISTRIBUTION_STATUS_PREFIX}${DistributionStatusFilter.ActionNeededByCurrentUser}`,
              text: t(DistributionStatusFilterKeys[DistributionStatusFilter.ActionNeededByCurrentUser]),
              value: false,
            },
          ]
        : [];

      return [
        ...filters,
        { id: `${PERIODIC_STATUS_PREFIX}${PeriodicReviewStatus.UnderReview}`, text: t('common:periodic-review-status.under-review'), value: false },
        { id: `${PERIODIC_STATUS_PREFIX}${PeriodicReviewStatus.ReviewOverdue}`, text: t('common:periodic-review-status.overdue'), value: false },
        ...distributionFilters,
      ];
    }
    return [
      ...filters,
      { id: `${PERIODIC_STATUS_PREFIX}${PeriodicReviewStatus.UpcomingReview}`, text: t('common:periodic-review-status.upcoming'), value: false },
      { id: `${PERIODIC_STATUS_PREFIX}${PeriodicReviewStatus.UnderReview}`, text: t('common:periodic-review-status.under-review'), value: false },
      { id: `${PERIODIC_STATUS_PREFIX}${PeriodicReviewStatus.ReviewCompleted}`, text: t('common:periodic-review-status.completed'), value: false },
      { id: `${PERIODIC_STATUS_PREFIX}${PeriodicReviewStatus.ReviewOverdue}`, text: t('common:periodic-review-status.overdue'), value: false },
    ];
  }, [currentPageType, featureFlags.distributionList, t, type]);

  const defaultRoleFilters = useMemo(
    () =>
      roleOptions.map((x) => ({
        id: x.id,
        text: t(ClientFormUserRoleKeys[x.value as ClientFormUserRoleValues]),
        value: false,
      })),
    [t],
  );

  const defaultRiskFilters = useMemo(
    () => [
      { id: RiskRating.HIGH_RISK, text: t('common:risk-level.high'), value: false },
      { id: RiskRating.MEDIUM_RISK, text: t('common:risk-level.medium'), value: false },
      { id: RiskRating.LOW_RISK, text: t('common:risk-level.low'), value: false },
      { id: RiskRating.NO_RISK, text: t('common:risk-level.no-risk'), value: false },
    ],
    [t],
  );

  const defaultDueDateFilters = useMemo(
    () => [
      { id: DueDateFilter.Today, text: t('common:list.filter.due-date-today'), value: false },
      { id: DueDateFilter.Week, text: t('common:list.filter.due-date-week'), value: false },
      { id: DueDateFilter.Overdue, text: t('common:list.filter.due-date-over'), value: false },
    ],
    [t],
  );

  const [templateFilterDefaults, setTemplateFilterDefaults] = useState<Option<string, boolean>[]>([]);
  const [documentFilterDefaults, setDocumentFilterDefaults] = useState<Option<string, boolean>[]>([]);
  const [clientUsersFilterDefaults, setClientUsersFilterDefaults] = useState<Option<string, boolean>[]>([]);

  const filterDefaults = useMemo(
    () => ({
      statusFilters: defaultStatusFilters,
      roleFilters: defaultRoleFilters,
      riskFilters: defaultRiskFilters,
      dueDateFilters: defaultDueDateFilters,
      templateFilters: templateFilterDefaults,
      documentFilters: documentFilterDefaults,
      clientUsersFilters: clientUsersFilterDefaults,
    }),
    [
      clientUsersFilterDefaults,
      defaultDueDateFilters,
      defaultRiskFilters,
      defaultRoleFilters,
      defaultStatusFilters,
      documentFilterDefaults,
      templateFilterDefaults,
    ],
  );

  const [
    { statusFilters, roleFilters, riskFilters, dueDateFilters, templateFilters, clientUsersFilters, documentFilters },
    { setStatusFilters, setRoleFilters, setRiskFilters, setDueDateFilters, setTemplateFilters, setClientUsersFilters, setDocumentFilters },
    { hasFiltersApplied, clearFilters },
  ] = useStoredFilters(`${currentPageId}`, filterDefaults);

  const clearAllFilters = useCallback(() => {
    clearFilters();
    setPicklistFilters([]);
  }, [clearFilters, setPicklistFilters]);

  const [filterArchived, setFilterArchived] = useState(!!statusFilters.find((x) => x.id === `${FORM_STATUS_PREFIX}${ARCHIVED_VALUE}`)?.value);

  const applyStatusFilter = useCallback(
    (filters: Option<string, boolean>[]) => {
      setStatusFilters(filters);
      setFilterArchived(!!filters.find((x) => x.id === `${FORM_STATUS_PREFIX}${ARCHIVED_VALUE}`)?.value);
    },
    [setStatusFilters],
  );

  const applyRiskFilter = useCallback(
    (filters: Option<RiskRating, boolean>[]) => {
      setRiskFilters(filters);
    },
    [setRiskFilters],
  );

  const applyUsersFilter = useCallback(
    (filters: Option<string, boolean>[]) => {
      setClientUsersFilters(filters);
    },
    [setClientUsersFilters],
  );

  const applyDueDateFilter = useCallback(
    (filters: Option<DueDateFilter, boolean>[]) => {
      const selectedDueDate = filters.find((x) => x.value);
      setDueDateFilters(filters);
      if (selectedDueDate) {
        switch (selectedDueDate.id) {
          case DueDateFilter.Today:
            setSelectedDueDateRangeFilter({ start: DateUtils.todayStart, end: DateUtils.todayEnd });
            break;
          case DueDateFilter.Week:
            setSelectedDueDateRangeFilter({ start: DateUtils.todayStart, end: DateUtils.addDays(7, DateUtils.todayEnd) });
            break;
          case DueDateFilter.Overdue:
            setSelectedDueDateRangeFilter({ start: DateUtils.minDate, end: DateUtils.addDays(-1, DateUtils.todayEnd) });
            break;
          default:
            setSelectedDueDateRangeFilter(null);
            break;
        }
      } else {
        setSelectedDueDateRangeFilter(null);
      }
    },
    [setDueDateFilters],
  );

  const applyTemplateFilter = useCallback(
    (templateFilter: Option<string, boolean>[]) => {
      setTemplateFilters((prev) => prev.map((x) => templateFilter.find((filter) => x.id === filter.id) || x));
    },
    [setTemplateFilters],
  );

  const applyDocumentFilter = useCallback(
    (documentFilter: Option<string, boolean>[]) => {
      setDocumentFilters((prev) => prev.map((x) => documentFilter.find((filter) => x.id === filter.id) || x));
    },
    [setDocumentFilters],
  );

  const onSortBy = (expression: string) => {
    setActivities(null);
    setCurrentPage(1);
    setSortBy(expression);
  };

  const sortByWithTitle = useMemo(() => {
    if (type === 'activities') {
      return sortBy;
    }
    return sortBy
      .split(',')
      .map((x) => (x.includes('subtitle') ? x + (x.startsWith('+') ? ',+title' : ',-title') : x))
      .join(',');
  }, [sortBy, type]);

  const itemSelection = useItemSelection();
  const mutateSelection = itemSelection.mutateSelection || {};
  const mutateSetFilter = mutateSelection.setFilter || null;

  const formStatusFilters = useMemo(
    () =>
      statusFilters
        .filter((x) => `${x.id}`.startsWith(FORM_STATUS_PREFIX))
        .map((x) => ({ ...x, id: parseInt(`${x.id}`.substring(FORM_STATUS_PREFIX.length), 10) })),
    [statusFilters],
  );

  const periodicStatusFilters = useMemo(
    () =>
      statusFilters
        .filter((x) => `${x.id}`.startsWith(PERIODIC_STATUS_PREFIX))
        .map((x) => ({ ...x, id: parseInt(`${x.id}`.substring(PERIODIC_STATUS_PREFIX.length), 10) })),
    [statusFilters],
  );

  const distributionStatusFilters = useMemo(
    () =>
      statusFilters
        .filter((x) => `${x.id}`.startsWith(DISTRIBUTION_STATUS_PREFIX))
        .map((x) => ({ ...x, id: parseInt(`${x.id}`.substring(DISTRIBUTION_STATUS_PREFIX.length), 10) })),
    [statusFilters],
  );

  const formsFilter = useMemo(() => {
    if (!currentUser || !allFiltersDataLoaded) return;

    const filteredFormStatuses = formStatusFilters.filter((filter) => filter.value && filter.id !== ARCHIVED_VALUE);
    return {
      clientModuleId: params.moduleId,
      title: debouncedSearchTerm,
      isArchived: filterArchived,
      types: [FormType.Asset, FormType.Default, FormType.Startup],
      roles: roleFilters.filter((filter) => filter.value).map((filter) => parseInt(filter.id, 10) as ClientFormUserRoleValues),
      users: forCurrentUser ? [currentUser.id || ''] : clientUsersFilters.filter((filter) => filter.value).map((filter) => filter.id),
      dueDateRange: selectedDueDateRangeFilter || undefined,
      formStatus:
        filteredFormStatuses.length === 0
          ? formStatusFilters.map((filter) => filter.id).filter((x) => x !== ARCHIVED_VALUE)
          : filteredFormStatuses.map((filter) => filter.id),
      periodicReviewStatuses: periodicStatusFilters.filter((filter) => filter.value).map((filter) => filter.id),
      distributionStatus: distributionStatusFilters.filter((filter) => filter.value).map((filter) => filter.id),
      riskStatuses: riskFilters.filter((filter) => filter.value).map((filter) => filter.id),
      templateIds: templateFilters
        .concat(documentFilters)
        .filter((x) => x.value)
        .flatMap((x) => x.id.split(',')),
      picklistIds: Object.assign({}, ...picklistFilters.map((x) => ({ [x.id]: x.value }))),
      pageSize: 15,
      sortBy: sortByWithTitle,
      importId: searchParams.get('importId'),
      templateModuleSectionId: moduleSectionId,
    };
  }, [
    currentUser,
    allFiltersDataLoaded,
    formStatusFilters,
    params.moduleId,
    debouncedSearchTerm,
    filterArchived,
    roleFilters,
    forCurrentUser,
    clientUsersFilters,
    selectedDueDateRangeFilter,
    periodicStatusFilters,
    distributionStatusFilters,
    riskFilters,
    templateFilters,
    documentFilters,
    picklistFilters,
    sortByWithTitle,
    searchParams,
    moduleSectionId,
  ]);

  useEffect(
    function resetFilters() {
      setCurrentPage(1);
      setActivities(null);
      // Reset when any of these filters change
    },
    [
      formsFilter?.title,
      formsFilter?.types,
      formsFilter?.roles,
      formsFilter?.users,
      formsFilter?.dueDateRange?.start,
      formsFilter?.dueDateRange?.end,
      formsFilter?.formStatus,
      formsFilter?.templateIds,
      formsFilter?.sortBy,
      formsFilter?.picklistIds,
      formsFilter?.isArchived,
      formsFilter?.riskStatuses,
      formsFilter?.templateModuleSectionId,
    ],
  );

  const requestAbortController = useRef<AbortController>();

  const filterForms = useCallback(() => {
    if (!formsFilter || loading || !currentClient || !allFiltersDataLoaded) return;

    const scrollTop = pageContentRef.current?.scrollTop || 0;
    setIsActivitiesLoading(true);
    const service =
      type === 'activities'
        ? picklistFilters.length > 0
          ? ClientFormService.getFormsDataWarehousePaged.bind(ClientFormService)
          : ClientFormService.getFormsPaged.bind(ClientFormService)
        : ClientFormService.getTasksPaged.bind(ClientFormService);
    mutateSetFilter && mutateSetFilter(formsFilter);
    if (requestAbortController.current) {
      requestAbortController.current.abort();
    }
    requestAbortController.current = new AbortController();
    service(currentClient.id, { ...formsFilter, pageNumber: currentPage }, { signal: requestAbortController.current.signal }).then((res) => {
      setActivities((prev) => {
        if (prev?.data && currentPage > 1) {
          return { ...res, data: [...prev.data, ...res.data] };
        }
        return { ...res };
      });
      setIsActivitiesLoading(false);

      nextTick(() => {
        pageContentRef.current?.scrollTo({ top: scrollTop });
      });
    });
  }, [formsFilter, loading, currentClient, allFiltersDataLoaded, type, picklistFilters.length, mutateSetFilter, currentPage]);

  const ownersFilterSearch = useMemo(
    () =>
      throttle((searchTerm: string) => {
        setClientUsersFilterDefaults((prev) => {
          // merge the previous filtered result with source
          const mergedUsers = [...prev, ...clientUsers];

          // remove duplicates
          const set = new Set();
          const unionArray = mergedUsers.filter((user) => {
            if (!set.has(user.id)) {
              set.add(user.id);
              return true;
            }
            return false;
          }, set);

          const result = unionArray
            .filter((user) => {
              const value = searchTerm.toLowerCase();
              return user.text.toLowerCase().search(value) > -1;
            })
            .sort((a, b) => (a.text > b.text ? 1 : -1));

          return result;
        });
      }, 500),
    [clientUsers],
  );

  useEffect(() => {
    if (!currentClient?.id) {
      return;
    }

    setTemplateFilterDefaults([]);
    setDocumentFilterDefaults([]);
    setClientUsersFilterDefaults([]);
    setAllFiltersDataLoaded(false);

    const clientTemplateModuleService = new ClientTemplateModuleService(currentClient.id);

    const getTemplates = clientTemplateModuleService
      .getAllTemplateForms({ latestOnly: true, types: [FormType.Asset], clientModuleId: modulePageId || undefined })
      .then((res) => {
        setTemplateFilterDefaults(
          res.data.map((form) => ({
            id: form.id,
            text: LanguageUtils.getTranslation('title', form.translations, currentLanguage),
            value: false,
          })),
        );
      });

    const getDocumentTemplates = clientTemplateModuleService.getTemplates(false, true, [ModuleType.Document]).then((moduleTemplateRes) => {
      const documentTemplates = moduleTemplateRes.data.flatMap((template) => {
        const templateIds = template.sections.flatMap((x) => x.forms.map((x) => x.templateFormId));
        return [
          {
            id: templateIds.join(','),
            text: LanguageUtils.getTranslation('name', template.translations, currentLanguage),
            value: false,
          },
        ];
      });
      setDocumentFilterDefaults(documentTemplates);
    });

    const getUsers = ClientService.getUsers().then((res) => {
      const users = res
        .sort((a, b) => (`${a.firstName}` > `${b.firstName}` ? 1 : `${b.lastName}` > `${a.lastName}` ? -1 : 0))
        .map((user) => ({
          id: `${user.id}`,
          text: user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : `${user.email}`,
          value: hasPermission(Roles.TeamMember) ? false : currentUser?.id === user.id,
        }));
      setClientUsers(users);
      setClientUsersFilterDefaults(users);
    });

    Promise.all([getTemplates, getDocumentTemplates, getUsers]).then(() => {
      setAllFiltersDataLoaded(true);
    });
  }, [currentClient?.id, currentLanguage, modulePageId, hasPermission, currentUser?.id]);

  const filteredTemplateFilters = useMemo(() => {
    const value = templateFilterSearch.toLowerCase();
    return templateFilters.filter((x) => x.text.toLowerCase().indexOf(value) > -1).sort((a, b) => a.text.localeCompare(b.text));
  }, [templateFilterSearch, templateFilters]);

  const filteredDocumentFilters = useMemo(() => {
    const value = documentFilterSearch.toLowerCase();
    return documentFilters.filter((x) => x.text.toLowerCase().indexOf(value) > -1).sort((a, b) => a.text.localeCompare(b.text));
  }, [documentFilterSearch, documentFilters]);

  const onPicklistFilterUpdate = useCallback(
    (picklist: Option<string, string[]>) => {
      setPicklistFilters((prev) => {
        const result = [...prev];
        const found = result.find((x) => x.id === picklist.id);
        if (found) {
          found.value = picklist.value;
        } else {
          result.push(picklist);
        }
        return result;
      });
    },
    [setPicklistFilters],
  );

  const onPicklistFilterRemove = useCallback(
    (picklistId: string) => {
      setPicklistFilters((prev) => prev.filter((x) => x.id !== picklistId));
    },
    [setPicklistFilters],
  );

  const debouncedFilterForms = useDebounce(filterForms, 700);
  useEffect(() => {
    debouncedFilterForms();
  }, [debouncedFilterForms]);

  useEffect(() => {
    EventSystem.listen('data-import-done', filterForms);
    return () => EventSystem.stopListening('data-import-done', filterForms);
  }, [filterForms]);

  useEffect(
    function typeChange() {
      setCurrentPage(1);
      setActivities(null);
    },
    [forCurrentUser],
  );

  useEffect(function langaugeChanged() {
    const handler = () => setActivities(null);

    EventSystem.listen('language-changed', handler);
    return () => {
      EventSystem.stopListening('language-changed', handler);
    };
  }, []);

  return (
    <div className="flex min-h-full flex-col">
      <div className="bg-background-1 sticky top-[-2px] z-40 w-full">
        {AboveFiltersSlot()}
        <div className="flex items-center justify-between px-4 py-1">
          <div className="flex flex-wrap items-center gap-2">
            <FilterTag
              tag={{ id: 'status', text: t(`common:list.filter.status`), value: 'status' }}
              mode={FilterSelectMode.Multi}
              onFiltersChange={applyStatusFilter}
              options={
                type === 'activities' ? statusFilters : statusFilters.filter((filter) => filter.id !== `${FORM_STATUS_PREFIX}${ARCHIVED_VALUE}`)
              }
            />
            <FilterTag
              tag={{ id: 'asset-type', text: t(`common:list.filter.asset-type`), value: 'asset-type' }}
              onFiltersChange={applyTemplateFilter}
              options={filteredTemplateFilters}
              mode={FilterSelectMode.Multi}
              onSearch={setTemplateFilterSearch}
            />
            {type === 'tasks' && (
              <FilterTag
                tag={{ id: 'document-type', text: t(`common:list.filter.document-type`), value: 'document-type' }}
                onFiltersChange={applyDocumentFilter}
                options={filteredDocumentFilters}
                mode={FilterSelectMode.Multi}
                onSearch={setDocumentFilterSearch}
              />
            )}
            {hasPermission(Roles.TeamMember) && !forCurrentUser && (
              <FilterTag
                tag={{ id: 'user', text: t(`common:list.filter.user`), value: 'user' }}
                onFiltersChange={applyUsersFilter}
                mode={FilterSelectMode.Multi}
                options={clientUsersFilters}
                onSearch={ownersFilterSearch}
              />
            )}
            {(clientUsersFilters.filter((x) => x.value).length > 0 || forCurrentUser) && (
              <FilterTag
                tag={{ id: 'roles', text: t(`common:list.filter.role`), value: 'roles' }}
                onFiltersChange={setRoleFilters}
                mode={FilterSelectMode.Multi}
                options={roleFilters}
              />
            )}
            <FilterTag
              tag={{ id: 'duedate', text: t(`common:list.filter.due-date`), value: 'duedate' }}
              onFiltersChange={applyDueDateFilter}
              options={dueDateFilters}
            />
            <FilterTag
              tag={{ id: 'risks', text: t(`common:list.filter.risk`), value: 'risk' }}
              onFiltersChange={applyRiskFilter}
              mode={FilterSelectMode.Multi}
              options={riskFilters}
            />
            {type === 'activities' && (
              <DynamicFilterTags state={picklistFilters} onFilterRemove={onPicklistFilterRemove} onFilterSelectionUpdate={onPicklistFilterUpdate} />
            )}
            {(hasFiltersApplied || hasPicklistFilters) && (
              <>
                <div className="border-gray-2 ml-4 mt-4 h-[25px] border-l" />
                <Button type={ButtonType.TERTIARY} className="mt-4" onClick={clearAllFilters}>
                  <span className="border-primary-1 border-b-2">{t('common:list.filter.clear-all-filters')}</span>
                </Button>
              </>
            )}
          </div>
          <div className="flex items-center justify-center gap-4">
            <div className="flex w-80">
              <SearchInput
                data-cy="activity-search"
                placeholder={t('common:list.filter.search')}
                value={activitySearchPhrase}
                style={InputStyle.MINIMAL}
                onChange={(e) => {
                  setCurrentPage(1);
                  setActivitySearchPhrase(e.target.value);
                }}
              />
            </div>
            {AfterSearchSlot()}
          </div>
        </div>
      </div>
      <div className="flex h-full w-full flex-grow flex-col" ref={pageContentRef}>
        <div className="h-full px-4 pb-4">
          <div className="bg-background-1 h-full py-4">
            {AboveListSlot()}
            <SkeletonLoader type="listBlockRow" rows={skeletonRows} size="medium" ready={!!activities}>
              {activities && currentUser?.id && (
                <ActivityList
                  activitiesPaged={activities}
                  onLoadMore={setCurrentPage}
                  onSort={onSortBy}
                  sortBy={sortBy}
                  isLoading={isActivitiesLoading}
                />
              )}
            </SkeletonLoader>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Activities;
