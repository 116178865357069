import { FC, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ApiResponse } from '../../models/ApiResponse';
import SortableHeading from '../shared/data-grid/SortableHeading';
import useInfiniteScroll from '../../hooks/useInfiniteScroll';
import Loader from '../shared/Loader';
import ActivityRow from './ActivityRow';
import { FormListItem } from '../../models/Form';
import ClientFormService from '../../services/ClientFormService';
import { useActivites } from '../../contexts/ActivitiesContext';
import EmptyTaskList from '../tasks/EmptyTaskList';
import InfoIcon from '../shared/icon/InfoIcon';
import { ModuleSection } from '../../models/Module';
import { useDownloadPdf } from '../../hooks/useDownloadPdf';
import FormUtils from '../../utils/FormUtils';
import { ModalContext } from '../../contexts/ModalContext';
import ConfirmationModal from '../shared/modal/variants/ConfirmationModal';
import Button, { ButtonType } from '../shared/form-control/Button';
import { useItemSelection } from '../../contexts/select-items/SelectItemsContext';
import LanguageUtils from '../../utils/LanguageUtils';

export type ModuleActivity = {
  section: ModuleSection;
  forms: FormListItem[];
};

type ActivityListProps = {
  activitiesPaged: ApiResponse<FormListItem[]>;
  onLoadMore?: (pageNumber: number) => void;
  sortBy: string;
  onSort?: (expression: string) => void;
  isLoading?: boolean;
};

const ActivityList: FC<ActivityListProps> = (props) => {
  const { activitiesPaged, onLoadMore, onSort, sortBy, isLoading = false } = props;
  const { clientModule, type, hasContextMenu } = useActivites();

  const [sortExpressions, setSortExpressions] = useState<Record<string, string>>({});

  const { t } = useTranslation(['task-list', 'common', 'module', 'assets']);
  const currentPage = activitiesPaged.pageNumber || 0;
  const totalPages = activitiesPaged.totalPages || 0;
  const [showArchiveConfim, setShowArchiveConfim] = useState(false);
  const [activityToArchive, setActivityToArchive] = useState<FormListItem | null>(null);
  const [excludeActivityIds, setExcludeActivityIds] = useState<string[]>([]);

  const updateSortExpression = (field: string, expression: string) => {
    const expressions = { ...sortExpressions, [field]: expression };
    setSortExpressions(expressions);

    const fullExpression = Object.values(expressions).filter((x) => x !== expression);
    fullExpression.unshift(expression); // move to first

    onSort && onSort(fullExpression.join(','));
  };

  useEffect(() => {
    let expressions = {};
    for (const expression of sortBy.split(',')) {
      expressions = { ...expressions, [expression.replace(/[-+]/g, '')]: expression };
    }
    setSortExpressions(expressions);
  }, [sortBy]);

  useEffect(() => {
    setExcludeActivityIds([]);
  }, [activitiesPaged]);

  const [lastElementRef] = useInfiniteScroll(currentPage < totalPages ? () => onLoadMore && onLoadMore(currentPage + 1) : null, isLoading);

  const archiveActivity = (activityId: string) => {
    ClientFormService.archiveForm(activityId).then(() => {
      setExcludeActivityIds((prev) => [...prev, activityId]);
      setShowArchiveConfim(false);
    });
  };

  const restoreActivity = (activityId: string) => {
    ClientFormService.restoreForm(activityId).then(() => {
      setExcludeActivityIds((prev) => [...prev, activityId]);
    });
  };

  const activities = useMemo(() => {
    const activities = activitiesPaged.data.filter((activity) => !excludeActivityIds.includes(activity.id));
    return activities;
  }, [activitiesPaged.data, excludeActivityIds]);

  const { SelectionBarContent, SelectAllButton, selection, mutateSelection } = useItemSelection();

  const selectText = useMemo(() => {
    if (selection.selectedAll || selection.selectedCount === activitiesPaged.totalCount) {
      return clientModule ? 'task-list:selection.all-modules' : 'task-list:selection.all-assets';
    }

    if (selection.selectedCount === activitiesPaged.data.length) {
      return clientModule ? 'task-list:selection.all-visible-modules' : 'task-list:selection.all-visible-assets';
    }

    return 'task-list:selection.some';
  }, [activitiesPaged.data.length, activitiesPaged.totalCount, clientModule, selection.selectedAll, selection.selectedCount]);

  const { triggerPdfDownload, pdfDownloadModal } = useDownloadPdf();

  const selectionBarText = useMemo(
    () => (
      <Trans
        t={t}
        i18nKey={selectText}
        tOptions={{
          Count: selection.selectedCount,
          Total: (activitiesPaged.totalCount ?? 0) - selection.excludedIds.length,
          Module: LanguageUtils.getTranslation('name', clientModule?.templateModule.translations || {}),
        }}
        components={{
          Link: (
            <Button
              className="!p-0"
              type={ButtonType.TERTIARY}
              onClick={() =>
                selection.selectedAll || selection.selectedCount === activitiesPaged.totalCount
                  ? mutateSelection.clear(activities)
                  : mutateSelection.selectAll(true)
              }
            />
          ),
        }}
      />
    ),
    [
      activities,
      activitiesPaged.totalCount,
      clientModule?.templateModule.translations,
      mutateSelection,
      selectText,
      selection.excludedIds.length,
      selection.selectedAll,
      selection.selectedCount,
      t,
    ],
  );

  return (
    <>
      <div className="bg-gray-5 rounded-[10px] px-2 py-3">
        <div className={`flex w-full items-end border-2 border-transparent pl-4 ${selection.active ? 'items-center' : 'items-end'}`}>
          {!selection.isDisabled && <div className="w-10">{SelectAllButton({ visibleItems: activities })}</div>}
          {selection.active ? (
            SelectionBarContent({
              selectionText: selectionBarText,
              clientModule,
            })
          ) : (
            <>
              <div className="flex flex-grow text-left">
                <div className="w-12">{/* SPACER */}</div>
                <SortableHeading title={t('task-list:list.heading.name')} onSort={updateSortExpression} expression={sortExpressions['subtitle']} />
              </div>
              <div className="w-56">
                <SortableHeading title={t('task-list:list.heading.due')} onSort={updateSortExpression} expression={sortExpressions['dueDateUtc']} />
              </div>
              <div className="w-32">{t('task-list:list.heading.members')}</div>
              <div className="w-48 pr-6 text-center">{t('task-list:list.heading.status')}</div>
              <div className="w-40 pr-6">{t('task-list:list.heading.progress')}</div>
              <div className="w-10">{t('task-list:list.heading.risk')}</div>
              <div className="w-20">{/* SPACER */}</div>
            </>
          )}
        </div>
        {activities.map((activity, i) => {
          const isLast = activities.length === i + 1;
          return (
            <div key={activity.id} ref={isLast ? lastElementRef : undefined}>
              <ActivityRow
                data-cy={`activity-list-item-${i}`}
                selected={selection.isSelected(activity)}
                onSelected={(value) => mutateSelection.selectIndividual(activity, value)}
                activity={activity}
                onDownload={(id) => triggerPdfDownload(id, FormUtils.getFormTitle({ form: activity, subtitle: activity.subtitle }))}
                onArchive={(activity: FormListItem) => {
                  setActivityToArchive(activity);
                  setShowArchiveConfim(true);
                }}
                onRestore={restoreActivity}
              />
            </div>
          );
        })}
      </div>

      {isLoading && (
        <div className="flex flex-col items-center py-6">
          <Loader size={16} centered={false} />
        </div>
      )}
      {!isLoading && activitiesPaged.data.length === 0 && (
        <>
          {type === 'tasks' ? (
            <EmptyTaskList />
          ) : (
            <div data-cy="tasks-empty" className="mx-auto flex w-1/2 flex-col items-center justify-center p-16 text-center">
              <InfoIcon className="bg-primary-1 text-primary-1 h-16 w-16 rounded-full bg-opacity-10 p-4" />
              <div className="text-dpm-20 text-color-3 mt-8">{t(`assets:list.no-assets-filtered.heading`)}</div>
            </div>
          )}
        </>
      )}
      {!!hasContextMenu && (
        <>
          <ModalContext.Provider value={{ open: showArchiveConfim, onClose: () => setShowArchiveConfim(false), modalWidth: 'w-2/5' }}>
            <ConfirmationModal
              title={t('assets:list.archive-modal.heading')}
              description={activityToArchive?.isShared ? t('assets:list.archive-modal.body-shared') : t('assets:list.archive-modal.body')}
              confirmText={t('assets:list.archive-modal.buttons.archive')}
              cancelText={t('assets:list.archive-modal.buttons.cancel')}
              onConfirm={() => activityToArchive && archiveActivity(activityToArchive.id)}
              onCancel={() => setShowArchiveConfim(false)}
              alt
            />
          </ModalContext.Provider>

          {pdfDownloadModal}
        </>
      )}
    </>
  );
};

export default ActivityList;
