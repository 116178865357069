import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { PickListItem } from '../../../models/Picklist';
import { currentClientAtom } from '../../../recoil/atoms/Clients';
import PickListService from '../../../services/PickListService';
import LanguageUtils from '../../../utils/LanguageUtils';
import { Option } from '../../Option';
import { SelectListMenu } from '../SelectListMenu';
import FilterTag, { FilterSelectMode } from './FilterTag';

type DynamicFilterTagsProps = {
  state: Option<string, string[]>[];
  onFilterRemove: (itemId: string) => void;
  onFilterSelectionUpdate: (picklist: Option<string, string[]>) => void;
};

const DynamicFilterTags: FC<DynamicFilterTagsProps> = (props) => {
  const { state, onFilterRemove, onFilterSelectionUpdate } = props;
  const [loaded, setLoaded] = useState(false);
  const [addDropdownActive, setAddDropdownActive] = useState(false);
  const [picklists, setPicklists] = useState<Option<string, string>[]>([]);
  const [picklistItems, setPicklistItems] = useState<Record<string, PickListItem[]>>({});
  const fetchedPicklistItems = useRef<string[]>([]);
  const { t } = useTranslation('common');
  const client = useRecoilValue(currentClientAtom);

  const fetchFilterItems = useCallback((itemId: string) => {
    if (fetchedPicklistItems.current.includes(itemId)) {
      return;
    }

    fetchedPicklistItems.current.push(itemId);
    PickListService.getPickList(itemId).then((res) => {
      setPicklistItems((prev) => ({
        ...prev,
        [itemId]: res.data.items,
      }));
    });
  }, []);

  useEffect(() => {
    for (const filter of state) {
      fetchFilterItems(filter.id);
    }
  }, [fetchFilterItems, state]);

  const picklistValues = useMemo(() => {
    const result: Option<string, Option<string, boolean>[]>[] = [];

    for (const { id: filterId } of state) {
      const activeItems = state.find((x) => x.id === filterId)?.value;
      result.push({
        id: filterId,
        text: picklists.find((x) => x.id === filterId)?.text || '',
        value:
          picklistItems[filterId]?.map((x) => ({
            id: x.id,
            text: LanguageUtils.getTranslation('name', x.translations),
            value: activeItems?.includes(x.id) ?? false,
          })) || [],
      });
    }

    return result;
  }, [picklistItems, picklists, state]);
  const [filterSearches, setFilterSearches] = useState<Record<string, string>>({});

  useEffect(() => {
    PickListService.getAll({ pageNumber: 1, pageSize: 500, clientId: client?.id }).then((res) => {
      setLoaded(true);
      setPicklists(
        res.data.map((picklist) => ({
          id: picklist.id,
          text: LanguageUtils.getTranslation('name', picklist.translations),
          value: picklist.id,
        })),
      );
    });
  }, [client?.id]);

  const addFilter = useCallback(
    (item: Option<string, string | number>) => {
      const picklist = picklists.find((picklist) => picklist.id == item.id);
      if (!picklist) {
        return;
      }

      fetchFilterItems(item.id);
      onFilterSelectionUpdate({ id: picklist.id, text: picklist.text, value: [] });
      setAddDropdownActive(false);
    },
    [fetchFilterItems, onFilterSelectionUpdate, picklists],
  );

  const onFilterChange = useCallback(
    (filterId: string, filterText: string, newValues: Option<string, boolean>[]) => {
      const tickedValues = newValues.filter((x) => x.value);
      const values = tickedValues.map((x) => x.id);
      onFilterSelectionUpdate({ id: filterId, text: filterText, value: values });
    },
    [onFilterSelectionUpdate],
  );

  const filteredPicklists = useMemo(() => {
    return picklists.filter((picklist) => !picklistValues.find((x) => x.id === picklist.id)?.value?.length);
  }, [picklistValues, picklists]);

  const filterFilterItems = useCallback(
    (picklistId: string) => {
      const values = picklistValues.find((x) => x.id === picklistId);
      if (!values) {
        return [];
      }

      const searchPhrase = filterSearches[picklistId]?.toLocaleLowerCase();
      if (!searchPhrase) {
        return values.value;
      }

      return values.value.filter((item) => item.text.toLocaleLowerCase().indexOf(searchPhrase) > -1);
    },
    [filterSearches, picklistValues],
  );

  const onSearch = useCallback((filterId: string, term: string) => {
    setFilterSearches((prev) => ({ ...prev, [filterId]: term }));
  }, []);

  const removeFilter = useCallback(
    (filterId: string) => {
      setFilterSearches((prev) => {
        const { [filterId]: _, ...rest } = prev;
        return rest;
      });
      onFilterRemove(filterId);
    },
    [onFilterRemove],
  );

  return loaded ? (
    <>
      {state.map((filter) => (
        <FilterTag
          key={filter.id}
          options={filterFilterItems(filter.id)}
          tag={{
            id: filter.id,
            text: t('list.dynamic-filters.title', { filter: picklists.find((x) => x.id === filter.id)?.text }),
            value: filter.id,
          }}
          onFiltersChange={(newValues) => onFilterChange(filter.id, filter.text, newValues)}
          mode={FilterSelectMode.Multi}
          onSearch={(term) => onSearch(filter.id, term)}
          removeFilter={() => removeFilter(filter.id)}
        />
      ))}

      {filteredPicklists.length > 0 && (
        <SelectListMenu
          enableSearching
          isOpen={addDropdownActive}
          options={filteredPicklists}
          onBlur={() => setAddDropdownActive(false)}
          onClick={(item) => addFilter(item)}
          width="w-72"
        >
          {(triggerProps) => (
            <div {...triggerProps} className="relative ml-2">
              <div className="mt-4 cursor-pointer" onClick={() => setAddDropdownActive((prev) => !prev)} data-cy="add-dynamic-filter">
                {t('list.dynamic-filters.add')}
              </div>
            </div>
          )}
        </SelectListMenu>
      )}
    </>
  ) : null;
};

export default DynamicFilterTags;
