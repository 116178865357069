import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import illustration from '../../assets/images/empty-list.svg';

const EmptyTaskList: FC = () => {
  const { t } = useTranslation('task-list');
  return (
    <div data-cy="task-list-empty" className="mt-12 flex h-full flex-row items-center justify-center gap-4">
      <div>
        <img src={illustration} className="object-cover" alt={t('list.empty.heading')} />
      </div>
      <div>
        <div className="font-medium"> {t('list.empty.heading')}</div>
        <div className="text-dpm-12 max-w-md">{t('list.empty.sub-heading')}</div>
      </div>
    </div>
  );
};

export default EmptyTaskList;
